import React from 'react';
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';

export interface TextInputComponentProps
  extends Omit<OutlinedTextFieldProps, 'variant'> {
  name?: string;
  width?: string;
  withHelper?: boolean;
}

export const TextInput: React.FC<TextInputComponentProps> = (props) => 
  (<TextField
      {...props}
      inputProps={{
        ...props.inputProps,
        'data-testid': `TextInputId-${props?.name}`,
      }}
      size="small"
      color="primary"
      fullWidth={true}
      variant={'outlined' as any}
  />);
