import React from 'react';
import { FastField, FastFieldProps } from 'formik';
import { TextInputComponentProps, TextInput } from './TextInput';

export const TextField: React.FC<TextInputComponentProps> = (props) => {
  const { withHelper, ...rest } = props;

  return (
    <FastField name={props.name}>
      {({ field, meta }: FastFieldProps) => {
        return (
          <TextInput
            {...rest}
            {...field}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && props.withHelper && meta.error}
          />
        );
      }}
    </FastField>
  );
};

TextField.defaultProps = {
  withHelper: true,
};
