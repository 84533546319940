import { Box, Paper, Typography } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { AuthTestRequest } from '../../shared/model/AuthTestRequest';
import { INITIAL_AUTH_FORM_VALUES } from '../../shared/model/constants';
import { authTestRequestAtom } from '../../shared/state/atoms';
import { AuthFormInputs } from './AuthFormInputs';

export const AuthForm: React.FC  = () => {
    const [ , setAuthTestRequest] = useRecoilState(authTestRequestAtom);
    const navigate = useNavigate();

    const handleFormSubmit = (authTestRequest: AuthTestRequest) => {
        setAuthTestRequest(authTestRequest);
        navigate('/auth-test');
    }

    return (
        <Box sx={{ mx: "auto", p: 4 }}>
            <Typography align='center' variant='h4'>IDB OIDC TEST HARNESS</Typography>
            <Paper>
                <Formik onSubmit={handleFormSubmit}
                    initialValues={INITIAL_AUTH_FORM_VALUES}
                    component={AuthFormInputs} />
            </Paper>
        </Box>
    );
}