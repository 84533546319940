import { Button, Stack } from '@mui/material';
import { Form, FormikProps } from 'formik';
import React from 'react';
import { AuthTestRequest } from '../../shared/model/AuthTestRequest';
import { TextField } from '../../shared/forms/TextField';
import { CLIENT_ID_PLACEHOLDER, ACR_VALUES_PLACEHOLDER, CLAIMS_PLACEHOLDER } from '../../shared/model/constants';

export const AuthFormInputs: React.FC<FormikProps<AuthTestRequest>> = (props) => (
    <Form>
        <Stack sx={{ p: 4 }} spacing={2}>
            <TextField name="authUrl" label="Auth Url" id="authUrl" disabled/>
            <TextField name="realm" label="Realm" id="realm"/>
            <TextField name="clientId" 
                label="Client ID" 
                id="clientId" 
                value={CLIENT_ID_PLACEHOLDER}/>
            <TextField name="acrValues" 
                label="Requested ACR Values" 
                id="acrValues" 
                placeholder={ACR_VALUES_PLACEHOLDER}/>
            <TextField name="claims" 
                label="Requested Claims" 
                id="claims"
                placeholder={CLAIMS_PLACEHOLDER} 
                multiline
                rows={4} />
            <TextField name="scope" 
                label="Scopes" 
                id="scopes" />
            <TextField name="idpHint" 
                label="IDP Hint" 
                id="idpHint" />
        </Stack>
        <Stack sx={{ pb: 2 }} direction="row" justifyContent="center">
            <Button type="submit" 
                variant="contained" 
                color="primary"
                id="submitButton" 
                disabled={!props.isValid}>
                    Login
            </Button>
        </Stack>
    </Form>);