import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthForm } from '../authTester/form/AuthForm';
import { Results } from '../authTester/response/Results';
import './App.css';

const Health: React.FC = () => (<h3>Hey There!!! The App is Healthy</h3>);

const App: React.FC = () =>
  (<div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthForm />} />
        <Route path="/auth-test" element={<Results />} />
        <Route path="/health" element={<Health />} />
      </Routes>
    </BrowserRouter>
  </div>);

export default App;
