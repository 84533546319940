import { AuthTestRequest } from "./AuthTestRequest";
import { TAlgorithm } from "jwt-simple";

export const INITIAL_KEYCLOAK_STATE = {} as Keycloak.KeycloakInstance;

export const INITIAL_AUTH_FORM_VALUES: AuthTestRequest = {
    authUrl: ((window as any).env ?? process?.env)?.REACT_APP_KEYCLOAK_AUTH_URL,
    realm: ((window as any).env ?? process?.env)?.REACT_APP_KEYCLOAK_REALM,
    clientId: ((window as any).env ?? process?.env)?.REACT_APP_KEYCLOAK_CLIENT_ID,
};

export const FIELD_WIDTHS = {
    small: '15ch',
    medium: '30ch',
    large: '60ch',
    fullWidth: '100%',
};

export const CLAIMS_ALGORITHM: TAlgorithm = "HS512";

export const CLIENT_ID_PLACEHOLDER = "Client must be public with urls configured";
export const ACR_VALUES_PLACEHOLDER = "Space-separated TDIF URNs";
export const CLAIMS_PLACEHOLDER = 'Claims object, e.g.\n{"id_token":{"acr":{"essential":true,"values":["urn:id.gov.au:tdif:acr:ip2:cl3"]}},"userinfo":{"email":null,"email_verified":null}}';