import {Alert, Box, Button, Link, Paper, Stack, TextField, Typography} from '@mui/material';
import Keycloak, {KeycloakLoginOptions, KeycloakPkceMethod} from 'keycloak-js';
import React, {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {authTestRequestAtom, keycloakStateAtom} from '../../shared/state/atoms';
import {DetailCard} from './DetailCard';
import {ACR_VALUES_PLACEHOLDER} from "../../shared/model/constants";

export const Results: React.FC = () => {
    const [error, setError] = useState();
    const [authTestRequest, setAuthTestRequest] = useRecoilState(authTestRequestAtom);
    const [keycloakState, setKeycloakState] = useRecoilState(keycloakStateAtom);
    const [acrValues, setAcrValues] = useState<string>();
    const [scope, setScope] = useState<string>();

    useEffect(() => {
        if (!Object.keys(keycloakState).length) {
            const keycloak = new Keycloak({
                url: authTestRequest.authUrl,
                realm: authTestRequest.realm,
                clientId: authTestRequest.clientId,
            });

            keycloak.init({
                checkLoginIframe: false,
                enableLogging: true,
                pkceMethod: 'S256' as KeycloakPkceMethod
            }).then(() => {
                keycloak.authenticated
                    ? setKeycloakState(keycloak)
                    : keycloak.login({
                        idpHint: authTestRequest.idpHint,
                        scope: authTestRequest.scope,
                        acrValues: authTestRequest.acrValues,
                        claims: authTestRequest.claims
                    } as KeycloakLoginOptions & {claims: any});
            })
            .catch((err) => {
                console.error(err);
                setError(err);
            })
        }
    }, [authTestRequest, keycloakState, setKeycloakState, setError]);

    useEffect(() => {
        if (keycloakState?.authenticated) {
            const {idpHint, ...rest} = authTestRequest;
            setAuthTestRequest(rest);
        }
    }, [keycloakState, authTestRequest, setAuthTestRequest])

    const tokenToString = (parsedToken: Keycloak.KeycloakTokenParsed) => {
        return JSON.stringify(parsedToken!, null, 2);
    }

    const handleLogout = () => {
        keycloakState.logout({
            redirectUri: ((window as any).env ?? process?.env)?.REACT_APP_KEYCLOAK_REDIRECT_URI
        }).catch(error => console.log(error));
    }

    const stepUp = () => {
        keycloakState.login({ scope, acrValues: acrValues }).catch(error => console.error(error));
    }

    const accountTransferIdp = ((window as any).env ?? process?.env)?.REACT_APP_ACCOUNT_TRANSFER_IDP

    return (<Box sx={{ mx: "auto", p: 4 }}>
        {error &&
            <div>
                <Alert severity="error" style={{ marginTop:'2em'}}
                        action={<Button color="inherit" size="small" onClick={() => window.location.reload()}>RETRY</Button>}>
                    <code>{JSON.stringify(error, null, 2)}</code>
                </Alert>
            </div>}
        {!!Object.keys(keycloakState).length && <>
            {accountTransferIdp &&
                <Stack sx={{ p: 4 }} direction="row" justifyContent="center" spacing={2}>
                    <Link href={`${authTestRequest.authUrl}/realms/${authTestRequest.realm}/account-transfer?idp=${accountTransferIdp}&clientId=oidc-test-harness&redirectUri=${encodeURIComponent(window.location.href)}`} style={{marginLeft: '1em'}}>
                        transfer account to {accountTransferIdp}
                    </Link>
                </Stack>
            }
            <Paper>
                <Stack sx={{ p: 4 }} spacing={2}>
                    <DetailCard title="Access Token" id="accessToken" contents={keycloakState?.token!} />
                    <DetailCard title="Parsed Access Token"
                        id="accessTokenParsed"
                        contents={tokenToString(keycloakState?.tokenParsed!)}
                        pretty
                    />
                    <DetailCard title="ID Token" id="idToken" contents={keycloakState?.idToken!} />
                    <DetailCard title="Parsed ID Token"
                        id="idTokenParsed"
                        contents={tokenToString(keycloakState?.idTokenParsed!)}
                        pretty
                    />
                    <DetailCard title="Refresh Token" id="refreshToken" contents={keycloakState?.refreshToken!} />
                    <DetailCard title="Parsed Refresh Token"
                        id="refreshTokenParsed"
                        contents={tokenToString(keycloakState?.refreshTokenParsed!)}
                        pretty
                    />
                </Stack>
                <Stack sx={{ pb: 2 }} direction="row" justifyContent="center">
                    <Button variant="contained"
                        id="logoutButton"
                        color="primary"
                        onClick={handleLogout}>
                            Logout
                    </Button>
                </Stack>
            </Paper>

            <Paper sx={{ mt: 2 }}>
                <Stack sx={{ p: 4 }} spacing={2}>
                <Typography align='center' variant='h5'>Step Up</Typography>
                    <Stack spacing={2}>
                        <TextField name="acrValues"
                            label="ACR Values"
                            id="acrValue"
                            placeholder={ACR_VALUES_PLACEHOLDER}
                            onChange={({target: {value}}) => setAcrValues(value)}
                        />
                        <TextField name="scope" sx={{p:0}}
                            label="Scope"
                            size="small"
                            value={scope}
                            onChange={({target: {value}}) => setScope(value)}
                        />
                    </Stack>
                    <Stack sx={{ pb: 2 }} direction="row" justifyContent="center">
                        <Button type="submit"
                            variant="contained"
                            color="primary"
                            id="submitButton"
                            onClick={stepUp}>
                                Step Up
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </>}
    </Box>);
}